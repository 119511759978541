import React from "react";
import theme from "theme";
import { Theme, Text, Box, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Autobahn Legends Club
			</title>
			<meta name={"description"} content={"Досконалість на колесах – відчуйте гордість німецької техніки"} />
			<meta property={"og:title"} content={"Autobahn Legends Club"} />
			<meta property={"og:description"} content={"Досконалість на колесах – відчуйте гордість німецької техніки"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6659a0828d4a0c00202fd67d/images/1-2.jpg?v=2024-05-31T12:02:30.799Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6659a0828d4a0c00202fd67d/images/1-2.jpg?v=2024-05-31T12:02:30.799Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6659a0828d4a0c00202fd67d/images/1-2.jpg?v=2024-05-31T12:02:30.799Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6659a0828d4a0c00202fd67d/images/1-2.jpg?v=2024-05-31T12:02:30.799Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6659a0828d4a0c00202fd67d/images/1-2.jpg?v=2024-05-31T12:02:30.799Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6659a0828d4a0c00202fd67d/images/1-2.jpg?v=2024-05-31T12:02:30.799Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6659a0828d4a0c00202fd67d/images/1-2.jpg?v=2024-05-31T12:02:30.799Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text" />
			<Override slot="text1" />
		</Components.Header>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-9">
			<Override slot="SectionContent" md-margin="0px 35px 0px 35px" sm-margin="0px 15px 0px 15px" />
			<Box
				min-width="10px"
				min-height="10px"
				display="flex"
				justify-content="space-between"
				align-items="center"
				margin="0px 0px 70px 0px"
				lg-flex-direction="column"
				text-align="center"
				flex-direction="column"
				max-width="100%"
			>
				<Text margin="0px 0px 20px 0px" color="--darkL1" font="--base">
					У Autobahn Legends Club ми надаємо ексклюзивний набір послуг, призначений для любителів автомобілів Audi, BMW і Mercedes. Наші пропозиції розроблені для того, щоб покращити ваш досвід знайомства з німецькою автомобільною досконалістю, гарантуючи, що кожен учасник відчує престиж і хвилювання від володіння та водіння цих легендарних автомобілів.
				</Text>
				<Text
					margin="0px 0px 25px 0px"
					font="normal 600 52px/1.2 --fontFamily-sans"
					color="--darkL1"
					lg-margin="0px 0px 30px 0px"
					lg-text-align="center"
				>
					Преміум пропозиції
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-rows="auto"
				grid-gap="0 35px"
				margin="0px 0px 40px 0px"
				md-grid-gap="35px 0"
				md-margin="0px 0px 35px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6659a0828d4a0c00202fd67d/images/1-3.jpg?v=2024-05-31T12:02:30.730Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 600 20px/1.2 --fontFamily-sans" color="--light" text-align="center">
							Ексклюзивні події водіння
						</Text>
						<Text
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#bec4ca"
							margin="16px 25px 16px 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
							text-align="center"
						>
							Мальовничі подорожі: Відчуйте захоплюючі краєвиди на підібраних маршрутах.{"\n"}
							<br />
							Track Days: Перевірте можливості свого автомобіля в безпечному контрольованому середовищі.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6659a0828d4a0c00202fd67d/images/1-2%20%281%29.jpg?v=2024-05-31T12:02:30.754Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 600 20px/1.2 --fontFamily-sans" color="--light" text-align="center">
							Технічні семінари
						</Text>
						<Text
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#bec4ca"
							margin="16px 25px 16px 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
							text-align="center"
						>
							Клініки технічного обслуговування: Навчіться підтримувати свій автомобіль у відмінному стані за допомогою порад експертів.{"\n"}
							<br />
							{" "}Сеанси оновлення:  Досліджуйте варіанти модифікації для підвищення продуктивності та естетики.
						</Text>
					</LinkBox>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-rows="auto"
				grid-gap="0 35px"
				lg-grid-gap="40px 35px"
				md-grid-gap="35px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6659a0828d4a0c00202fd67d/images/1-1.jpg?v=2024-05-31T12:02:30.731Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 600 20px/1.2 --fontFamily-sans" color="--light" text-align="center">
							Культурні збори
						</Text>
						<Text
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#bec4ca"
							margin="16px 25px 16px 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
						>
							Автосалони: Доступ до приватних виставок, де демонструються класичні та сучасні німецькі автомобілі.{"\n"}
							<br />
							Соціальні зустрічі: Зв’яжіться зі спільнотою, яка поділяє вашу пристрасть і інтерес.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6659a0828d4a0c00202fd67d/images/2-1.jpg?v=2024-05-31T12:02:30.764Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 600 20px/1.2 --fontFamily-sans" color="--light" text-align="center">
							Відкрийте для себе більше з нами
						</Text>
						<Text
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#bec4ca"
							margin="16px 25px 16px 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
						>
							Пропозиції в Autobahn Legends Club – це лише початок. Кожна послуга – це шлях до глибшого вивчення та оцінки того, що означає бути частиною німецької автомобільної спадщини. Завітайте до нас і відкрийте для себе повний спектр наших послуг, кожна з яких створена для покращення вашого досвіду та взаємодії зі світом високопродуктивних автомобілів.
						</Text>
					</LinkBox>
				</Box>
			</Box>
		</Section>
		<Components.Contacts>
			<Override slot="text">
				Відчуйте легендарний сервіс
			</Override>
			<Override slot="text1">
				Познайомтеся з суттю німецької інженерії через Клуб легенд автобану. Кожен поворот керма, кожне перемикання передач — це запрошення у світ захоплення та розкоші. Приєднуйтесь до нас і пориньте у свою власну легенду.
			</Override>
		</Components.Contacts>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bb71f98e2e8e0021771970"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});